import { render, staticRenderFns } from "./CreateAlert.vue?vue&type=template&id=115ab3c0&scoped=true&"
import script from "./CreateAlert.vue?vue&type=script&lang=js&"
export * from "./CreateAlert.vue?vue&type=script&lang=js&"
import style0 from "./CreateAlert.vue?vue&type=style&index=0&id=115ab3c0&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "115ab3c0",
  null
  
)

export default component.exports